import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ReqAuthLogin, ResAuthLogin, ResAuthLoginDataCredencial, ResAuthLoginDataUsuario } from '@core/models/login/auth.model';
import { SessionStorageService } from '@core/services/common/session-storage.service';
import { environment } from '@envs/environment.dev';
import { EstadoDatosService } from '@shared/services/estado-datos.service';
import { Observable, of } from 'rxjs';
import { DataSession } from 'src/app/core/enums/session/data.enum';
import { UtilRuta } from 'src/app/core/utils/ruta.util';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private http = inject(HttpClient);
  private router = inject(Router);
  private sessionStorageSvc = inject(SessionStorageService);
  private estadoDatosSvc = inject(EstadoDatosService);
  private logueado: WritableSignal<boolean | undefined> = signal(false);
  URL_BASE = environment.API_URL;

  constructor() {
    this.checkToken();
  }

  get isLogueado(): Signal<boolean | undefined> {
    return this.logueado;
  }

  setSessionUsuario(data: ResAuthLogin): void {
    this.logueado.set(true);
    this.sessionStorageSvc.setItem(DataSession.USUARIO, data.data.usuario);
    this.sessionStorageSvc.setItem(DataSession.TOKEN, data.data.credenciales);
    if (!data.data.usuario.rol) {
      this.logout();
      return;
    }
    const idRol = data.data.usuario.rol.id;
    this.redireccionarRol(idRol);
  }

  get dataUsuario(): ResAuthLoginDataUsuario | null {
    if (!this.sessionStorageSvc.getItemObj(DataSession.USUARIO)) {
      return null;
    }
    return this.sessionStorageSvc.getItemObj<ResAuthLoginDataUsuario>(DataSession.USUARIO);
  }

  private checkToken(): void {
    const sessionData = this.sessionStorageSvc.getItemObj<ResAuthLoginDataCredencial>(DataSession.TOKEN);
    if (!sessionData) {
      this.logueado.set(false);
      return;
    }
    this.logueado.set(true);
  }

  login$(dataLogin: ReqAuthLogin): Observable<ResAuthLogin> {
    // return of(
    //   {
    //     "message": "SUCCESS",
    //     "data": {
    //       "propertiesUpdate": null,
    //       "usuario": {
    //         "idUsuario": "0447c5cc-06e5-4bcf-94c8-6e63f9f4d54f",
    //         "codigoQr": "iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQAAAACFI5MzAAABeElEQVR4Xu2WQW6DQAxFPcqCJUeYm4SLISVSLkZvwhFYzgLh/u+hhSTt9tNKWErk4c3CGvvbNv/N7PXDt53kJLS/QUYzu3i5Wp+bD7/j1KoJfo+xdJPlcm0f7oucFIuz3caScMeuhxCe79m644g39Gy9oybO/DRDO2d6b5kTENboyOLg31v1Ckg1SxBIDauehQSxISJIdDZbxaomDhcPg3YBD5naZU5DnFnJ7BSGjwgwfcWmIx0eBp2it/C211ER6hRNguXZDHbjRTEJYY7hlIQn2nQqIyHR2ikWdK3nzElIrdFVp8jUljkVgToxMJPP2dLUc2yICSXaZ+qUin1SsIo450RsDjG49r1KR1gc1KlhfTiA1Ea5cFwhU4ZMiUkYA/RVsTsFawhmFooDgws1GrvLFrWKOCvEuDz60L5WiITUDZI6bRbmpzuGhE4Ln4gBHkEs5rZPl31+VMRjh11qw+p3/VpGokZjcKFfo3N3avKzneQktP9JPgEyYYE1oZ0k5wAAAABJRU5ErkJggg==",
    //         "email": "alansalinasc@gmail.com",
    //         "tipoDocIdent": "DNI",
    //         "numeroDocIdent": "12341234",
    //         "nombres": "MARI YUI",
    //         "apellidos": "FLORES RAMOS",
    //         "celular": "914212512",
    //         "idNotificacion": null,
    //         "rol": {
    //           "id": 4,
    //           "nombreRol": "ROLE_VECINO",
    //           "descripcion": "Vecino"
    //         },
    //         "direccion": [
    //           {
    //             "idDireccion": 2,
    //             "descripcion": "Vecino",
    //             "referencia": "sisol salud la victoria",
    //             "latitud": "-12.060839",
    //             "longitud": "-77.031421",
    //             "idUbigeo": "150101",
    //             "idEstado": null
    //           }
    //         ]
    //       },
    //       "credenciales": {
    //         "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aXBvX2RvY3VtZW50byI6IkROSSIsImlkX3VzdWFyaW8iOiIwNDQ3YzVjYy0wNmU1LTRiY2YtOTRjOC02ZTYzZjlmNGQ1NGYiLCJ1c2VyX25hbWUiOiIxMjM0MTIzNCIsInNjb3BlIjpbInJlYWQiLCJ3cml0ZSJdLCJudW1lcm9fZG9jdW1lbnRvIjoiMTIzNDEyMzQiLCJleHAiOjE3MTc3MzA1MTEsImF1dGhvcml0aWVzIjpbIlJPTEVfVkVDSU5PIl0sImp0aSI6IjVjOTExYzNhLWZmODktNGQ1MS04Y2RjLWY0OGUzMWZiNjdiZSIsImNsaWVudF9pZCI6ImNsaWVudC1zZXJ2aWNlcyJ9.3LifVxIwHP5qzPgcRU3XbPaB-ZT3FUugvF7cLU6sDIE",
    //         "token_type": "bearer",
    //         "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aXBvX2RvY3VtZW50byI6IkROSSIsImlkX3VzdWFyaW8iOiIwNDQ3YzVjYy0wNmU1LTRiY2YtOTRjOC02ZTYzZjlmNGQ1NGYiLCJ1c2VyX25hbWUiOiIxMjM0MTIzNCIsInNjb3BlIjpbInJlYWQiLCJ3cml0ZSJdLCJhdGkiOiI1YzkxMWMzYS1mZjg5LTRkNTEtOGNkYy1mNDhlMzFmYjY3YmUiLCJudW1lcm9fZG9jdW1lbnRvIjoiMTIzNDEyMzQiLCJleHAiOjE3MTc3Mjg3MTEsImF1dGhvcml0aWVzIjpbIlJPTEVfVkVDSU5PIl0sImp0aSI6ImJkNGQxNDgzLTM1YWMtNGI2ZC05YjA2LTVlNDE2NDk4NDhlZSIsImNsaWVudF9pZCI6ImNsaWVudC1zZXJ2aWNlcyJ9.2B9junfRDuNylTrKUIY-di4FszxPBdAcdkc2F7MRipM",
    //         "expires_in": 2699,
    //         "scope": "read write",
    //         "tipo_documento": "DNI",
    //         "id_usuario": "0447c5cc-06e5-4bcf-94c8-6e63f9f4d54f",
    //         "numero_documento": "12341234",
    //         "jti": "5c911c3a-ff89-4d51-8cdc-f48e31fb67be"
    //       },
    //       "successLogin": false
    //     },
    //     "codResult": 1
    //   }
    // )

    const headers: HttpHeaders = this.authBasic({ password: dataLogin.password, username: dataLogin.username });
    const url = new URL(`${this.URL_BASE}/login`);
    return this.http.post<ResAuthLogin>(url.href, null, { headers });
  }

  recuperarClave(data: {numeroDocIdent: string}): Observable<any> {
    const url = new URL(`${this.URL_BASE}/clave/recuperar`);
    return this.http.post<any>(url.href, data);
  }

  cambiarClave(data: {token: string; nuevaClave: string}): Observable<any> {
    const url = new URL(`${this.URL_BASE}/clave`);
    return this.http.post<any>(url.href, data);
  }

  logout(): void {
    this.logueado.set(false);
    this.sessionStorageSvc.clearItems();
    this.estadoDatosSvc.selectRol('1');
    this.router.navigate(['/']);
  }

  redireccionarRol(rol: number) {
    const ruta = UtilRuta.irRutaPrincipal(rol);
    // console.log('RUTA REDIRECCIONAR ROL', ruta);
    this.router.navigate(ruta);
  }

  private authBasic({ username, password }: { username: string; password: string }) {
    const header = new HttpHeaders().append('Authorization', 'Basic ' + btoa(`${username}:${password}`));
    return header;
  }
}
